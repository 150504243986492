/* */
@tailwind base;
@tailwind components;
@tailwind utilities;

.font-jomhuria {
  font-family: 'Jomhuria', cursive;
  letter-spacing: 4px;
}

.font-gwendolyn {
  font-family: 'Gwendolyn', cursive;
}

.font-galdeano {
  font-family: 'Galdeano', sans-serif;
}

/* Base styles (Mobile first) */
.responsive-div {
  position: absolute;
  z-index: -10;
  top: -1rem;
  left: -1rem;
  padding: 1rem;
  border-width: 8px;
  /* border-color: var(--secondary-color); Define --secondary-color in your CSS */
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 600px;
  width: 99%;
  object-fit: cover;
}

/* Tablet devices (768px and up) */
@media (min-width: 768px) {
  .responsive-div {
    top: -2rem;
    left: -1.5rem;
    height: 600px;
    width: 99%;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .responsive-div {
    position: relative;
    top: 0;
    left: 0;
    height: 600px;
    width: 400px;
  }
}
